label {
    color: #fff;
    margin-bottom: 0.5rem;
}

form {
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    margin: auto;
    max-width: 700px;

}

.message-sent {
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    margin: auto;
    max-width: 700px;
    justify-content: center;
    text-align: center;

}


.input-field {
    border: 1px solid #ccc;
    padding: 8px;
    transition: border-color 0.3s ease;
    
  }
  
  .input-field:hover {
    border-color: #b700ff;
  }

form input, form textarea {
    margin-bottom: 1rem;
    padding: 10px 18px;
    padding-bottom: 2rem;
    font-size: 1.2rem;
    background-color: rgba(255, 255, 255, 0.678);
    border-color: rgba(255, 255, 255, 0.1);
    color: #000000;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0 rgba(255, 255, 255, 0.646);
}


