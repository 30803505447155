
.hero-section {
    width: 100%;
    height: 80vh;
    padding-top: 3.5rem;

}

.bg-image {
    /* The image used */
    background-image: url("../assets/KODUR.avif");
  
    /* Add the blur effect */

  
    /* Full height */
    height: 100%;
  
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }


.heading {
background-color: rgb(0,0,0); /* Fallback color */
background-color: rgba(114, 114, 114, 0.559); /* Black w/opacity/see-through */
color: white;
font-weight: bold;
backdrop-filter: blur(6px);
position: absolute;
top: 40%;
left: 50%;
transform: translate(-50%, -50%);
z-index: 2;
width: 80%;
padding: 20px;
text-align: center;
border-radius: 10px;
box-shadow: 0px 0px 20px 0 rgba(255, 255, 255, 0.646);
}

@media screen and (max-width: 640px) {
    .hero-section h1{
        font-size: 2.4rem;
    }
}