.about {
    width: 100%;
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.about .left {
    text-align: center;
    margin: auto;
    padding-left: 2rem;
    max-width: 450px;
}

.about .left p {
    margin: 1.2 rem 0;
    padding-bottom: 3rem;

}

.about .right {
    max-width: 700px;
    margin: auto

}

.right .img-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    align-items: center;
    text-align: center;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 3rem;
    z-index: 0;
}

.right .img {
    max-width: 100%;
    border: 1px solid #333;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.646);
}

.right .top {
    grid-column: 1 / span 8;
    grid-row: 1;
    padding-top: 10%;
    z-index: 6;
}

.right .bottom {
    grid-column: 4 / -1;
    grid-row: 1;
    z-index: 5;
}