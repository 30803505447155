

.skill-bar {
    width: 100%;
    background-color: #f2f2f260;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0px 0px 20px 0 rgba(0, 0, 0, 0.646);
  }
  
  .skill-bar-progress {
    background-color: #5d5d5d;
    height: 8px;
    border-radius: 4px;
  }
  
  .skill-bar-label {
    margin-top: 4px;
    margin-bottom: 4px;
  }