.gradient-background {
    background: linear-gradient(90deg, #777777, #BBBBBB, #614779);
    background-size: 400% 400%;
    animation: gradientAnimation 20s ease-in-out infinite;
  }
  
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }



  @import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
  *{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Abel', sans-serif;
  }
  

  h1, h4,  a {
    color: #fff;
    text-decoration: none;
    padding: 0.2rem;
    font-size: 1.8rem;
  }

  p {
    color: #fff;
    text-decoration: none;
    padding: 0.2rem;

  }
  
  a:hover {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    transition: 0.3s;
  }
  
  ul{
    list-style-type: none;
  }
  
  
  
  .btn {
    padding: 12px 32px;
    font-size: 1rem;
    text-transform: uppercase;
    background: rgb(81, 79, 79);
    color: white;
    margin-top: 2rem;
    font-weight: 600;
    cursor: pointer
  }
  
  .btn-light {
    padding: 12px 32px;
    margin-top: 2rem;
    font-size: 1rem;
    text-transform: uppercase;
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    font-weight: 600;
  }
  
  .btn:hover, .btn-light:hover {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    transition: 0.3s;
  }